import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnInit,
  signal,
  viewChild,
  ViewChild,
} from "@angular/core";
import { ImageViewComponent } from "../roam-layout/image-view/image-view.component";
import { RoamHoverModule } from "../roam-hover/roam-hover.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { UserPreviewCardComponent } from "../sidebar-user-profile/user-preview-card/user-preview-card.component";
import { TextAvatarLabelComponent } from "../roam-text-label/text-avatar-label/text-avatar-label.component";
import { IUserView } from "@app/shared/interfaces/IUserView.interface";
import {
  debounceTime,
  filter,
  fromEvent,
  share,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "user-avatar-stack",
  standalone: true,
  imports: [
    ImageViewComponent,
    RoamHoverModule,
    OverlayModule,
    UserPreviewCardComponent,
    TextAvatarLabelComponent,
  ],
  template: `
    <div class="user-group">
      @for (user of users().slice(0, users().length > 2 ? 1 : 2); track $index) {
        <div class="avatar">
          <app-image-view
            [image]="user.avatarUrl"
            [width]="'28px'"
            [height]="'28px'"
            [rounded]="true"
            [placeholderWidth]="'28px'"
            [placeholderHeight]="'28px'" />
        </div>
      }
      @if (users().length > 2) {
        <div class="counter">+{{ users().length - 1 }}</div>
      }
    </div>
    <div class="user-name" cdkOverlayOrigin #openDetail="cdkOverlayOrigin">
      @if (users() && users().length > 0) {
        @if (users().length === 1) {
          <a #userOver>
            {{ users()[0].name }}
          </a>
        } @else if (users().length === 2) {
          <a #userOver> {{ users()[0].name }} and {{ users()[1].name }} </a>
        } @else {
          <a #userOver class="c-pointer">
            {{ users()[0].name }} & {{ users().length - 1 }} others
          </a>
        }
      } @else {
        <span>N/A</span>
      }
    </div>

    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayOrigin]="openDetail"
      [cdkConnectedOverlayOpen]="isOpen()"
      cdkConnectedOverlayPush="true"
      [cdkConnectedOverlayViewportMargin]="7"
      (backdropClick)="isOpen.set(false)">
      <div class="dialog-container">
        @if (users().length === 1) {
          <user-preview-card
            [avatarUrl]="users()[0].avatarUrl"
            [name]="users()[0].name"
            [email]="users()[0].email" />
        } @else {
          <div class="d-grid gap-16">
            @for (user of users(); track $index) {
              <div class="align-center justify-between gap-30">
                <app-text-avatar-label
                  [image]="user.avatarUrl"
                  [title]="user.name"
                  [text]="user.email"
                  [imgHeight]="40"
                  type="title" />

                <button class="btn-base btn-view">View Detail</button>
              </div>
            }
          </div>
        }
      </div>
    </ng-template>
  `,
  styles: `
    :host {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .user-group {
      display: flex;

      .avatar {
        border: 2px solid #ffffff;
        border-radius: 100%;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: -12px;
          z-index: 1;
        }
      }

      .counter {
        width: 32px;
        height: 32px;
        margin-left: -14px;
        border: 2px solid #ffffff;
        background: #dddddd; 
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 500;
        z-index: 1;
      }
    }

    .user-name {
      color: #222222;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }

    .btn-view {
      width: 152px;
      border-radius: 29px;
      background: rgba(145, 70, 106, 0.1);
      padding: 10px;
      cursor: pointer;
    }
  `,
})
export class UserAvatarStackComponent implements AfterViewInit {
  readonly users = input<IUserView[]>([]);
  isOpen = signal(false);

  #destroyRef = inject(DestroyRef);

  @ViewChild("userOver") userOverEl!: ElementRef;

  ngAfterViewInit(): void {
    this.#setupHoverBehavior();
  }

  #setupHoverBehavior() {
    if (this.userOverEl) {
      const open$ = fromEvent(
        this.userOverEl?.nativeElement,
        "mouseenter"
      ).pipe(
        switchMap(enterEvent =>
          fromEvent(document, "mousemove").pipe(
            startWith(enterEvent),
            debounceTime(300),
            filter((event: any) => {
              const target = event.target as HTMLElement;
              return this.userOverEl?.nativeElement.contains(target);
            })
          )
        ),
        takeUntilDestroyed(this.#destroyRef),
        share()
      );

      open$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
        this.isOpen.set(true);
      });

      fromEvent(document, "mousemove")
        .pipe(
          debounceTime(300),
          filter((event: any) => {
            const target = event.target as HTMLElement;
            return (
              !this.userOverEl?.nativeElement.contains(target) &&
              !target.closest(".dialog-container")
            );
          }),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe(() => {
          this.isOpen.set(false);
        });
    }
  }

  openDetail() {
    this.isOpen.set(true);
  }
}
