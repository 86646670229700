import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from "@angular/core";
import { ChipUi } from "@app/pages/customer/+ui";
import { ContentVisibleHoverUi } from "@app/pages/job/+ui";
import { UserAvatarStackComponent } from "@app/shared/components/user-avatar-stack/user-avatar-stack.component";
import { SidebarDirectiveModule } from "@app/shared/directives/sidebar-directive/sidebar-directive.module";
import {
  ROAM_TABLE_BASE_MODULES,
  ROAM_TABLE_COL_DEF_BASE,
  RoamTableAccessor,
} from "@app/utils/table.utils";
import { ExtractEnum } from "@app/utils/typing.utils";
import { InlineSVGModule } from "ng-inline-svg-2";

export const COL_DEF_MEETING = {
  ...ROAM_TABLE_COL_DEF_BASE,
  CreatedDate: "createdDate",
  CreatedDateAndStatusName: "createdDate/statusName",
  PropertyAndAddress: "property/address",
  Address: "address",
  StaffAttended: "staffAttended",
  SubjectName: "subject",
  Notes: "notes",
} as const;

export type ColDefMeeting = ExtractEnum<typeof COL_DEF_MEETING>;

@Component({
  standalone: true,
  selector: "app-meetings-table-alt",
  styleUrl: "./meetings-table.cmp.scss",
  templateUrl: "meetings-table.cmp.html",
  host: { class: "roam-mdc-table-wrapper" },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ...ROAM_TABLE_BASE_MODULES,
    SidebarDirectiveModule,
    InlineSVGModule,
    ContentVisibleHoverUi,
    ChipUi,
    DatePipe,
    UserAvatarStackComponent,
  ],
})
export class MeetingsTableCmp extends RoamTableAccessor<any, ColDefMeeting> {
  readonly dateFmt = "MM/dd/yyyy";

  protected get opt() {
    return this.options();
  }

  protected get colDef() {
    return COL_DEF_MEETING;
  }

  protected getAttendees(attendees: any) {
    return attendees.map((attendee: any) => ({
      id: attendee.user.id,
      name: attendee.user.name,
      email: attendee.user.email,
      avatarUrl: attendee.user.avatarUrl,
    }));
  }

  constructor() {
    super({ modelKey: "id" });
  }
}
