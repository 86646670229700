<ng-template #statusCellView let-row>
  @switch (row.statusName) {
    @case ("Active") {
      <chip-ui size="sm" rounded="sm" status="info">
        {{ row.statusName }}
      </chip-ui>
    }
    @case ("Closed") {
      <chip-ui size="sm" rounded="sm" status="danger">
        {{ row.statusName }}
      </chip-ui>
    }
    @case ("Approved") {
      <chip-ui size="sm" rounded="sm" status="success">
        {{ row.statusName }}
      </chip-ui>
    }
    @case ("Pending") {
      <chip-ui size="sm" rounded="sm" status="warning">
        {{ row.statusName }}
      </chip-ui>
    }
    @default {
      <chip-ui size="sm" rounded="sm" status="basic">Unknown</chip-ui>
    }
  }
</ng-template>

<div class="roam-mdc-progress-bar-wrapper">
  @if (isLoading()) {
    <mat-progress-bar mode="indeterminate" />
  }
</div>

<table
  matSort
  mat-table
  matSortDisableClear
  [dataSource]="dataSource()"
  [matSortActive]="opt.sortActive"
  [matSortDirection]="opt.sortDirection"
  (matSortChange)="onSortChange($event)">
  <ng-container [matColumnDef]="colDef.Index">
    <th
      *matHeaderCellDef
      class="cell-header-index"
      mat-header-cell
      mat-sort-header>
      ID
    </th>
    <td class="cell-index" mat-cell *matCellDef="let i = index">
      {{
        paginatorPageIndex === 0 ?
          i + 1
        : 1 + i + paginatorPageIndex * paginatorPageSize
      }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.CreatedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.createdAt | date: dateFmt }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.CreatedDateAndStatusName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date / Status</th>
    <td mat-cell *matCellDef="let row">
      <p class="fw-600 mb-5">
        <a>
          {{ row.createdAt | date: dateFmt }}
        </a>
      </p>
      <p>
        <ng-template
          [ngTemplateOutlet]="statusCellView"
          [ngTemplateOutletContext]="{ $implicit: row }" />
      </p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.PropertyAndAddress">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div class="roam-whitespace-nowrap">Property/Address</div>
    </th>
    <td mat-cell *matCellDef="let row">
      <div>
        <div class="roam-flex roam-items-center roam-gap-2">
          <span inlineSVG="assets/svg/ic-location-ih.svg"></span>
          <span class="roam-line-clamp-1">
            {{ row.property?.name ?? "N/A" }}
          </span>
        </div>
        <div class="roam-flex roam-items-center roam-gap-2">
          <span inlineSVG="assets/svg/ic-door-ih.svg"></span>
          <span class="roam-line-clamp-1">
            {{ row.unit?.address ?? "N/A" }}
          </span>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.property?.address || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.SubjectName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.subject || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Notes">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
    <td mat-cell *matCellDef="let row">
      <p
        [matTooltip]="row.notes || 'N/A'"
        matTooltipPosition="above"
        class="roam-line-clamp-1">
        {{ row.notes || "N/A" }}
      </p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.StaffAttended">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Guest List</th>
    <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
      <div>
        <user-avatar-stack [users]="getAttendees(row.attendees)" />
      </div>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Selections">
    <th class="cell-selection" mat-header-cell *matHeaderCellDef>
      <div class="content-center">
        <mat-checkbox
          class="hover-visible"
          [checked]="allSelected()"
          (change)="toggleAll()" />
      </div>
    </th>
    <td mat-cell *matCellDef="let row" roamNoPropagation class="cell-selection">
      <div class="content-center">
        <mat-checkbox
          [checked]="isSelected(row[modelKey])"
          (change)="toggleOne(row[modelKey])"
          [class.visible]="isSelected(row[modelKey])"
          class="hover-visible" />
      </div>
    </td>
  </ng-container>

  <tr mat-header-row class="table-header" *matHeaderRowDef="columnDefs()"></tr>

  <tr
    mat-row
    class="table-row"
    contentVisibleHoverUi
    childSelector=".hover-visible"
    (click)="onRowClick(i, row)"
    [class.table-row-selected]="isSelected(row[modelKey])"
    *matRowDef="let row; columns: columnDefs(); index as i"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="columnDefs().length">
      <app-empty-state
        subtitle="No meetings available"
        title="Nothing to display" />
    </td>
  </tr>
</table>

@if (!isEmpty() && showPagination()) {
  <mat-paginator
    [length]="total()"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageIndex]="opt.pageIndex - 1"
    [pageSize]="opt.pageSize"
    (page)="onPage($event)"
    aria-label="Select page of meetings" />
}
